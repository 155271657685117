const experience = [
  {
    name: "The Roper Center",
    location: "Remote",
    stack: ["Java Servlet", "SpringBoot", "React", "jooq", "enzyme", "jest"],
    title: "Fall Software Engineering Co-op",
    dates: [new Date(2023, 7), null],
    img: "https://yt3.googleusercontent.com/ytc/AOPolaSXqA6mvXk42J2ovkvwKpxPzIr07jSTpBFVZeL2=s900-c-k-c0x00ffffff-no-rj",
  },
  {
    name: "Cornell Tech (Represent Research Group)",
    location: "Remote",
    stack: ["HCI", "React.js", "Python"],
    title: "Student Researcher",
    dates: [new Date(2023, 4), new Date(2023, 7)],
    img: "https://brand.tech.cornell.edu/wp-content/uploads/2022/06/Twisted-T-Lockup-Cornell-Red.png",
  },
  {
    name: "Swadesh (YC S'19)",
    location: "Remote",
    stack: ["Flutter", "Dart"],
    title: "Software Engineering Intern",
    dates: [new Date(2023, 1), new Date(2023, 3)],
    img: "https://media.licdn.com/dms/image/C4E0BAQFO0f8IHunNAQ/company-logo_200_200/0/1627649254166?e=2147483647&v=beta&t=UE_Nqsem44a8Yc_WJULM_0HONhnCGsswN08qgPjW4yg",
  },
  {
    name: "rapStudy",
    location: "Los Angeles, CA",
    stack: ["React Native", "Expo", "Firebase", "TS"],
    title: "Software Engineering Intern",
    dates: [new Date(2022, 11), new Date(2023, 0)],
    img: "https://c.neevacdn.com/image/fetch/s--5lMc1K1P--/https%3A//emilykam.com/images/rapstudy-img2.png?savepath=rapstudy-img2.png",
  },
  {
    name: "Sellpoint Inc.",
    location: "Boston, MA",
    stack: ["React", "AWS Lambda", "AWS APIGateway"],
    title: "Software Engineering Intern",
    dates: [new Date(2022, 5), new Date(2022, 7)],
    img: "https://www.sellpoint.io/favicon/apple-touch-icon.png",
  },
  {
    name: "Cornell ILR",
    location: "Ithaca, NY",
    stack: ["PHP", "Laravel", "JS"],
    title: "Student Software Engineer",
    dates: [new Date(2022, 7), new Date(2022, 11)],
    img: "https://c.neevacdn.net/image/fetch/s--ppsoF80p--/https%3A//media.univcomm.cornell.edu/photos/1280x720/D7A8A2A1-008D-B191-8B9971B5BC8E22C6.jpg?savepath=D7A8A2A1-008D-B191-8B9971B5BC8E22C6.jpg",
  },
  {
    name: "Cornell AAP",
    location: "Ithaca, NY",
    stack: ["Python", "Jupyter Notebook"],
    title: "Research Assistant",
    dates: [new Date(2022, 9), new Date(2022, 11)],
    img: "https://joshowendesign.imgix.net/wp-content/uploads/2017/06/cornell_aap-01.jpg?auto=format%2Ccompress",
  },
  {
    name: "Cornell DTI",
    location: "Ithaca, NY",
    stack: ["Flutter", "Nest.js", "Docker"],
    title: "Software Engineer",
    dates: [new Date(2021, 9), null],
    img: "https://cdn-images-1.medium.com/max/1200/1*gcJyMkO4WRmjuWmkSh61pw.png",
  },
  {
    name: "Unipantry",
    location: "Ithaca, NY",
    stack: ["Python", "Flask", "Firebase"],
    title: "Software Engineer Intern",
    dates: [new Date(2022, 0), new Date(2022, 3)],
    img: "https://c.neevacdn.net/image/fetch/s--uu5mnIbC--/https%3A//res.cloudinary.com/crunchbase-production/image/upload/c_lpad%2Ch_256%2Cw_256%2Cf_auto%2Cq_auto%3Aeco%2Cdpr_1/rfxsyhj2bxhsjg9rlt39?savepath=rfxsyhj2bxhsjg9rlt39",
  },
];

export default experience;
